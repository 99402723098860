
CustomEase.create(
  "hop",
  "0.86,0,0.07,1"
);

console.log(gsap);
console.log(ScrollTrigger);

// localStorage を使う場合
window.WebFontConfig = {
  google: { families: ['Noto+Sans+JP'] },
  active: function() {
    localStorage.fonts = true;
  }
};

(function() {
  var wf = document.createElement('script');
  wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
  wf.type = 'text/javascript';
  wf.async = 'true';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(wf, s);
})();


function resizeWindow(){
  //console.log('Resize');
  visual_height_fix();
}

window.addEventListener('resize', resizeWindow);






  inview();
  
 
  pagetop();
  
  smooth();
  faq();


  pc_menu();



function faq() {
  // FAQタイトルのクリックイベントリスナー
  const faqTitles = document.querySelectorAll('.faq_title');
  faqTitles.forEach(title => {
    title.addEventListener('click', () => {
      title.classList.toggle('isactive');
      const answer = title.nextElementSibling; // 次の要素（.faq_answer）を取得
      if (answer.style.display === 'block') {
        answer.style.display = 'none'; // 非表示にする場合はdisplay: none;を設定
      } else {
        answer.style.display = 'block'; // 表示にする場合はdisplay: block;を設定
      }

      title.parentElement.classList.toggle('active');
    });
  });

  // FAQボタンのクリックイベントリスナー
  const faqButtons = document.querySelectorAll('.faq-button');
  faqButtons.forEach(button => {
    button.addEventListener('click', () => {
      const text = button.previousElementSibling; // 前の要素（.faq-text）を取得

      if (text.style.display === 'block') {
        text.style.display = 'none';
      } else {
        text.style.display = 'block';
      }


      button.parentElement.classList.toggle('active');
    });
  });
}









function inview() {
  const elements = document.querySelectorAll('.inview'); // .inview クラスを持つ要素をすべて選択
  const options = {
    root: null, // ビューポートをルートとする
    rootMargin: '0px', // マージンなし
    threshold: 0.2 // 20%以上見えたら発火
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('active');
        // アニメーションが一度だけ実行されるように、監視を停止
        observer.unobserve(entry.target); 
      }
    });
  }, options);

  elements.forEach(element => {
    observer.observe(element);
  });
}

// DOMContentLoaded のタイミングで実行
document.addEventListener('DOMContentLoaded', () => {
    inview();
});


window.addEventListener('resize', () => {
    inview();
});



function pc_menu() {
  const menuButton = document.getElementById("menu");
  const gnav = document.getElementById("gnav");
  const bgb02 = document.querySelector(".bgb02");
  const gnavBgb = document.querySelector("#gnav .bgb");
  const mcon = document.querySelector(".mcon");
  const backBtnA = document.querySelector("#back_btn a");

  if(menuButton){
  menuButton.addEventListener("click", () => {
    gnav.classList.toggle("active");
    bgb02.classList.toggle("active");
    gsap.to(gnavBgb, { x: "0%", duration: 0.3, ease: "easeOutExpo" });
    if (!_ua.Mobile) {
      gsap.to(mcon, { x: "-360px", duration: 0.5, ease: "easeOutExpo" });
    }
  });
}
if(backBtnA){
  backBtnA.addEventListener("click", (event) => {
    event.preventDefault();
    gnav.classList.toggle("active");
    bgb02.classList.toggle("active");
    gsap.to(gnavBgb, { x: "100%", duration: 0.3, ease: "easeOutExpo" });
    gsap.to(mcon, { x: "0px", duration: 0.3, ease: "easeOutExpo" });
  });
}
}

let bodyElm = document.body; // body要素を直接取得
let scrollPosi;
function bodyFix() {
  scrollPosi = window.scrollY;
  bodyElm.style.position = 'fixed';
  bodyElm.style.width = '100%';
  bodyElm.style.zIndex = '1';
  bodyElm.style.top = -scrollPosi + 'px';
}

function visual_height_fix() {
  const headerHeight = document.querySelector('header').offsetHeight;
  const windowHeight = window.innerHeight;
  const heroHeight = windowHeight - headerHeight;
  const heroElement = document.getElementById('hero');
  const videoAreaElement = document.getElementById('video-area');

  if (heroElement) {
    heroElement.style.height = heroHeight + 'px';
  }
  if(videoAreaElement){
    videoAreaElement.style.top = headerHeight + 'px';
  }
}






function blankdwindow(){
  function ready(fn) {
    if (document.readyState != 'loading') {
      fn();
    } else if (document.addEventListener) {
      document.addEventListener('DOMContentLoaded', fn);
    } else {
      document.attachEvent('onreadystatechange', function() {
        if (document.readyState != 'loading')
          fn();
      });
    }
  }
  ready(function() {
    var website = window.location.hostnam;
    var internalLinkRegex = new RegExp('^((((http:\\/\\/|https:\\/\\/)(www\\.)?)?'
    //+ website
    + ')|(localhost:\\d{4})|(\\/.*))(\\/.*)?$', '');
  var anchorEls = document.querySelectorAll('a');
    var anchorElsLength = anchorEls.length;
  
  for (var i = 0; i < anchorElsLength; i++) {
    var anchorEl = anchorEls[i];
    var href = anchorEl.getAttribute('href');
  if (!internalLinkRegex.test(href)) {
    anchorEl.setAttribute('target', '_blank');
  anchorEl.setAttribute('rel', 'noopener nofollow');
      }
    }
  });
}



function coming() {
  // .coming a のクリックイベントリスナー
  const comingLinks = document.querySelectorAll('.coming a');
  comingLinks.forEach(link => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      return false; // これ以降の処理は不要なのでreturn false; は残しておく
    });
  });

  // .nolink a と a.nolink のクリックイベントリスナー (重複しているので1つにまとめる)
  const nolinkLinks = document.querySelectorAll('.nolink a, a.nolink'); // 両方のセレクタにマッチする要素を取得
  nolinkLinks.forEach(link => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
    });
  });
}





function smooth() {
  // #で始まるアンカーかつsmoothクラスを持つ要素を取得
  const smoothScrollLinks = document.querySelectorAll('a[href^="#"].smooth');

  
  smoothScrollLinks.forEach(link => {
    link.addEventListener('click', (event) => {
      event.preventDefault();
      const headerHeight = document.querySelector("header").offsetHeight;
      const targetId = link.getAttribute("href");

      // ターゲット要素の取得 (IDが空または#のみの場合はhtml要素)
      const targetElement = targetId === "#" || targetId === "" ? document.documentElement : document.querySelector(targetId);

      // ターゲット位置の計算
      let targetPosition;
      if (_ua.Mobile) {
        targetPosition = targetElement.offsetTop - 50;
      } else if (link.classList.contains("smin")) {
        targetPosition = targetElement.offsetTop - headerHeight;
      } else {
        targetPosition = targetElement.offsetTop;
      }

      // gsap でスムーススクロール
      gsap.to(window, { duration: 0.5, scrollY: targetPosition, ease: "easeOutexpo" });
    });
  });


}



function pagetop() {
  const pagetopButton = document.getElementById("pagetop");

  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      pagetopButton.classList.add("active");
    } else {
      pagetopButton.classList.remove("active");
    }
  });

  pagetopButton.querySelector("a").addEventListener("click", (event) => {
    event.preventDefault();
    gsap.to(window, {duration: 0.5, scrollTo: 0, ease: "easeOutexpo"}); // scrollTo を scrollY に変更
  });
  
}


const buttonElement = document.querySelector("#menu a");
const buttonBgElement = buttonElement?.querySelector(".bg");

const handleMouseEnterLeave = (event) => {
  if (!buttonElement || !buttonBgElement) {
    return;
  }
  const mouseTop = event.offsetY;
  const mouseLeft = event.offsetX;
  buttonBgElement.style.translate = `${mouseLeft}px ${mouseTop}px`;
};

buttonElement?.addEventListener("mouseenter", handleMouseEnterLeave);
buttonElement?.addEventListener("mouseleave", handleMouseEnterLeave);


// メニューの初期化関数を別途定義
function initializeMenu() {
  pc_menu();
}




// DOMContentLoadedとbarba.jsの両方で初期化を行う
function initializePage() {
  const namespace = document.querySelector('[data-barba="container"]')?.dataset.barbaNamespace;
  
  initializeMenu();
  smooth();
  // 共通の初期化
  initializeCommonFunctions();

  // 現在のページに応じた初期化
  switch (namespace) {
    case 'top':
      window.initializeSlider();
      inview();
      pagetop();
      visual_height_fix();
      
      break;
    case 'topmessage':
      inview();
      pagetop();
      // トップメッセージページ特有の初期化
      break;
    case 'salon':
      inview();
      pagetop();
      const swiper = new Swiper('#visual-swiper', {
        loop: true,
          speed: 3000,            //追加（スライドスピード）
          effect: 'fade',         //追加（フェードエフェクトを適用する）
          allowTouchMove: false,
      
          autoplay: {
              delay: 3000,
          },
              
          })
      // トップメッセージページ特有の初期化
      break;
    case 'neu':
      // neuページ特有の初期化
      inview();
      pagetop();
      // その他必要な初期化処理
      break;
  }
}



// 共通の初期化関数
function initializeCommonFunctions() {
  smooth();
  faq();

  coming();
  

}

  //スクロール位置記憶（auto or manual＝自動で記憶しない）
  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
  }
  
  let scrollY = 0;


// barba.jsの設定
barba.init({
  transitions: [{
    leave: function(data) {
      document.querySelector(".bgb02").classList.remove("active");
      gsap.to(".mcon", { x:"0px", duration: 0.3,ease:"easeOutExpo" });
      // クリーンアップ処理
      if (window.sliderInstance) {
        window.sliderInstance.renderer.dispose();
        window.sliderInstance.scene.remove(window.sliderInstance.scene.children[0]);
        window.sliderInstance = null;
      }
      return new Promise(function (resolve, reject) {
        leaveAnimation(data.current.container);
        setTimeout(function () {
          resolve();
        }, 800)
      });
    },
    enter: function(data) {
      // CSSの再読み込みを確認
     
      enterAnimation(data.next.container);
    },
    after: function(data) {
      // 遷移後の初期化
      
      requestAnimationFrame(() => { 
        if(data.trigger === 'back'){
            window.scrollTo(0, scrollY);
        } else {
            window.scrollTo(0, 0);
        }
     });
      //barba.history.current.scroll.yで遷移前のページのスクロールY座標を取得
      scrollY = barba.history.current.scroll.y;

      initializePage();
      // メニューを確実に初期化
      setTimeout(() => {
        initializeMenu();
      }, 100); // わずかな遅延を追加
    }
  }],
  views: [
    {
      namespace: 'top',
      afterEnter() {
        // トップページ特有の初期化処理
        window.initializeSlider();
        
      }
    },
    {
      namespace: 'topmessage',
      afterEnter() {
        // トップメッセージページ特有の初期化処理
        
      }
    },
    {
      namespace: 'neu',
      afterEnter() {
        // トップメッセージページ特有の初期化処理
        
      }
    }
  ]

});


// CSS読み込みの確認
function ensureStylesLoaded() {
  return new Promise((resolve) => {
    const links = document.querySelectorAll('link[rel="stylesheet"]');
    let loadedCount = 0;

    links.forEach(link => {
      if (link.sheet) { // 既に読み込まれている場合
        loadedCount++;
        if (loadedCount === links.length) resolve();
        return;
      }

      link.onload = () => {
        loadedCount++;
        if (loadedCount === links.length) resolve();
      };
      link.onerror = () => { // エラー処理も追加
        console.error(`Stylesheet '${link.href}' failed to load.`);
        loadedCount++; // カウントは進める
        if (loadedCount === links.length) resolve(); // 全てのスタイルシートの処理が完了したらresolve
      };
    });
  });
}


const eventDelete = e => {
  if (e.currentTarget.href === window.location.href) {
    e.preventDefault()
    e.stopPropagation()
    return
  }
}

const links = [...document.querySelectorAll('a[href]')]
links.forEach(link => {
  link.addEventListener('click', e => {
    eventDelete(e)
  }, false)
})

// animation.jsの設定
// 現在のページを離れる時のアニメーション
function leaveAnimation(e) {
  var tl = gsap.timeline();
  tl.set(".mask", { y: "-100%" });
  tl.to(".mask", { y: "0%", duration: 0.7, delay: 0.3, ease: "hop" });
  document.getElementById("gnav").classList.remove("active"); // jQueryを削除
  gsap.to("#gnav .bgb", { x: "100%", duration: 0.3, ease: "hop" });
}

function enterAnimation(e) {
  document.querySelector(".mask").style.y = "0%"; // jQueryを削除
  var tl = gsap.timeline();
  tl.to(".mask", { y: "100%", duration: 0.8, ease: "hop" });
}